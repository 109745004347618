import React from "react"
import { graphql } from "gatsby"

import Post from "../views/layouts/Post"
import get from "lodash/get"

const ContactPage = props => {
  const contents = get(
    props,
    "data.contactJson.modules[0].copy.childMarkdownRemark.html",
    ""
  )
  return (
    <Post location="联系我们">
      <div>
        {
          <div
            dangerouslySetInnerHTML={{
              __html: contents,
            }}
          />
        }
      </div>
    </Post>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query ContactQuery {
    contactJson: contentfulPage(slug: { eq: "contact" }) {
      modules {
        ... on ContentfulPageCopy {
          id
          copy {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`

// export const pageQuery = graphql`
//   query ContactQuery {
//     contactJson: contentfulPage(slug: {eq: "contact"}) {
//       modules {
//         ... on ContentfulPageCopy {
//           id
//           copy {
//             childMarkdownRemark {
//               html
//             }
//           }
//         }
//       }
//     }
//   }
// `
